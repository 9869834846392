type Props = {
  navigateToHome: () => void
  navigateToPrevious?: () => void
}

export const NotFound = ({ navigateToHome, navigateToPrevious }: Props) => (
  <div>
    <div>
      <div className="flex justify-center">
        {navigateToPrevious && (
          <button onClick={() => navigateToPrevious()}>
            Back
          </button>
        )}
        <button onClick={() => navigateToHome()}>
          Home
        </button>
      </div>
    </div>
  </div>
)
