import { Moon, Download,
  Network,
  Sun, Bell, PanelLeftClose, PanelLeftOpen, ChevronsUpDown, Plus, Search, CircleUser, LogOut, RefreshCw, Share2,
  ChevronRight} from 'lucide-react'

type IconProps = {
  className?: string
}

export const CollapsibleIcon = (props: IconProps) => <ChevronRight {...props} />

export const LogoutIcon = (props: IconProps) => <LogOut {...props} />
export const RefreshIcon = (props: IconProps) => <RefreshCw {...props} />
export const ShareIcon = (props: IconProps) => <Share2 {...props} />
export const DownloadIcon = (props: IconProps) => <Download {...props} />
export const SearchIcon = (props: IconProps) => <Search {...props} />

export const PipelineIcon = (props: IconProps) => <Network {...props} />

export const UserProfileIcon = (props: IconProps) => <CircleUser {...props} />

export const CreateIcon = (props: IconProps) => <Plus {...props} />

export const DropdownOpenIcon = (props: IconProps) => <ChevronsUpDown {...props} />

export const SidebarHideIcon = (props: IconProps) => <PanelLeftClose {...props} />
export const SidebarShowIcon = (props: IconProps) => <PanelLeftOpen {...props} />

export const LigthIcon = (props: IconProps) => <Sun {...props} />
export const DarkIcon = (props: IconProps) => <Moon {...props} />

export const NotificationIcon = (props: IconProps) => <Bell {...props} />
