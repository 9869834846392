import {Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator, Button, DarkIcon, LigthIcon, NotificationIcon, Popover, PopoverContent, PopoverTrigger} from '~/elements'
import { usePreferencesStore } from '~/stores'

export const Header = () => {
  const appearance = usePreferencesStore(s => s.appearance)
  const toggleAppearance = usePreferencesStore(s => s.toggleAppearance)

  return (
    <div className="h-14 p-2 px-4 border-b flex justify-between items-center">
      <div>
      <Breadcrumb>
        <BreadcrumbList className='text-sm'>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href="/components">Components</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Breadcrumb</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <h1 className='font-bold'>TestTestTestTestTest</h1>
      </div>
      <div>
      <Button variant="ghost" size='icon' onClick={toggleAppearance}>
        {
          appearance === 'dark' ? <DarkIcon /> : <LigthIcon />
        }
            </Button>
            <Popover>
          <PopoverTrigger>
            <Button variant="ghost" size='icon'>
              <NotificationIcon />
            </Button>
          </PopoverTrigger>
          <PopoverContent align='end'>
            <div>hi</div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}
