import type { ReactNode } from "react";
import { ClerkProvider } from "./clerk-provider";
import { ReactQueryProvider } from "./react-query-provider";
export { queryClient } from './react-query-provider'
import { ThemeProvider } from './theme-provider'
import { ShortcutsProvider } from './shortcuts-provider'
import { HotkeysProvider } from './hotkeys-provider'
import { SidebarProvider } from "./sidebar-provider";
import { ToastProvider } from "./toast-provider";

type ProvidersParams = {
	children: ReactNode;
};

export const Providers = ({ children }: ProvidersParams) => (<ClerkProvider>
	<ThemeProvider>
		<HotkeysProvider>
		<ReactQueryProvider>
			<SidebarProvider>
				<ShortcutsProvider />
				<ToastProvider />
				{children}
			</SidebarProvider>
		</ReactQueryProvider>
		</HotkeysProvider>
	</ThemeProvider>
</ClerkProvider>);
