import { create } from 'zustand'

type PreferencesState = {
  appearance: 'light' | 'dark'
  toggleAppearance: () => void
}

export const usePreferencesStore = create<PreferencesState>(set => ({
  appearance: 'light',
  toggleAppearance: () => set(state => ({ appearance: state.appearance === 'light' ? 'dark' : 'light' })),
}))
