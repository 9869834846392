import { useHotkeys } from 'react-hotkeys-hook'
import { useSidebarStore } from '~/stores'

const shortcuts = {
  sidebarToggle: 'mod+[',
  download: 'meta+s',
}

const defaultOptions = { preventDefault: true }

const download = () => {}

export const useShortcuts = () => {
  const toggleSidebar = useSidebarStore(state => state.toggleSidebar)

  useHotkeys(shortcuts.sidebarToggle, toggleSidebar, defaultOptions)
  useHotkeys(shortcuts.download, download, defaultOptions)
}
