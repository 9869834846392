import { create } from 'zustand'

type SidebarState = 'expanded' | 'collapsed'

type SidebarContext = {
  state: SidebarState
  setState: (state: SidebarState) => void
  isMobile: boolean
  setIsMobile: (isMobile: boolean) => void
  toggleSidebar: () => void
}

export const useSidebarStore = create<SidebarContext>(set => ({
  state: 'expanded',
  setState: (state: SidebarState) => set({ state }),
  isMobile: false,
  setIsMobile: (isMobile: boolean) => set({ isMobile }),
  toggleSidebar: () => set(({ state }) => ({ state: state === 'collapsed' ? 'expanded' : 'collapsed' })),
}))
