import { useIsMobile } from '~/hooks'
import { TooltipProvider } from './tooltip-provider'
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_ICON } from "~/const"
import { useSidebarStore } from '~/stores'

const SidebarProvider = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(
  (
    {
      className,
      style,
      children,
      ...props
    },
    ref
  ) => {
    const isMobile = useIsMobile()
    const sidebarStore = useSidebarStore()

    useEffect(() => {
      sidebarStore.setIsMobile(isMobile)
    }, [isMobile])

    return (
        <TooltipProvider delayDuration={0}>
          <div
            style={
              {
                "--sidebar-width": SIDEBAR_WIDTH,
                "--sidebar-width-icon": SIDEBAR_WIDTH_ICON,
                ...style,
              } as React.CSSProperties
            }
            className={cn(
              "group/sidebar-wrapper flex min-h-svh w-full has-[[data-variant=inset]]:bg-sidebar",
              className
            )}
            ref={ref}
            {...props}
          >
            {children}
          </div>
        </TooltipProvider>
    )
  }
)
SidebarProvider.displayName = "SidebarProvider"

export {
  SidebarProvider
}
