import { IconButton as RadixIconButton, type IconButtonProps } from '@radix-ui/themes'
import { ComponentProps, forwardRef } from 'react'

type Variant = IconButtonProps['variant'] | 'minimal'

export const IconButton = forwardRef<HTMLButtonElement, Omit<IconButtonProps, 'variant'> & { variant?: Variant }>(
  ({ variant = 'minimal', size = '2', className, ...props }, ref) => (
    <RadixIconButton
      {...props}
      size={size}
      variant={variant === 'minimal' ? 'soft' : variant}
      className={cn(className, variant === 'minimal' && 'bg-transparent hover:bg-gray-3', 'cursor-pointer')}
      ref={ref}
    />
  ),
)
