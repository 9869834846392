import { type QueryClient } from '@tanstack/react-query'
import { Outlet, createRootRouteWithContext, ScrollRestoration } from '@tanstack/react-router'
import { Sidebar, Content, Footer, Header } from '~/layouts'

type RouterContext = {
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
})

function RootComponent() {
  return (
    <>
      <ScrollRestoration />
      <div className="flex h-screen w-screen overflow-hidden flex-col">
        <div className="flex h-full w-full overflow-hidden">
          <Sidebar />
          <div className="flex flex-col w-full overflow-hidden">
            <Header />
            <Content>
              <Outlet />
            </Content>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}
