import {
	SignedIn,
	SignedOut,
	RedirectToSignIn,
} from "@clerk/clerk-react";
import { Providers } from "~/providers";
import { Router } from "~/router";

export const App = () => (
	<Providers>
		<SignedOut>
			<RedirectToSignIn />
		</SignedOut>
		<SignedIn>
			<Router />
		</SignedIn>
	</Providers>
);
