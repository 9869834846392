import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { NotFound } from '~/components'

export const Route = createFileRoute('/$')({
  component: NotFoundComponent
})

function NotFoundComponent() {
  const navigate = useNavigate()
  return <NotFound navigateToHome={() => void navigate({ to: '/' })} />
}
