import { Theme } from '@radix-ui/themes'
import { PropsWithChildren } from 'react'
import { usePreferencesStore } from '~/stores'

export const ThemeProvider = (props: PropsWithChildren) => {
  const appearance = usePreferencesStore(state => state.appearance)

  return (
    <Theme {...props} accentColor="purple" scaling="95%" grayColor="sage" appearance={appearance}>
      {props.children}
    </Theme>
  )
}
