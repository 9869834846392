import { useUser as useClerkUser } from '@clerk/clerk-react'

type User = {
  email: string
  avatarUrl: string
  initial: string
  name: string
}

export const useUser = () => {
  const { user: clerkUser } = useClerkUser()

  const [user, setUser] = useState<User>()

  useEffect(() => {
    if (clerkUser) {
      setUser({
        email: clerkUser.primaryEmailAddress?.emailAddress ?? '',
        avatarUrl: clerkUser.imageUrl,
        initial: `${clerkUser.firstName?.[0] ?? ''}${clerkUser.lastName?.[0] ?? ''}`.toUpperCase(),
        name: clerkUser.fullName ?? '',
      })
    }
  }, [clerkUser])

  return { user }
}
