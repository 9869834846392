import { ScrollArea } from "~/elements";

export const Content = ({ children }: { children: React.ReactNode }) => (
  <ScrollArea className="h-full">
  <div className="p-1 h-full">
  <div className="leading-none text-sm p-1">
  </div>
  <div className="rounded-sm px-2 h-full">
    {children}
  </div>
</div>
</ScrollArea>
)
