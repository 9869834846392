import * as React from 'react'
import { Label } from './label'

const FileInput = React.forwardRef<HTMLInputElement, React.ComponentProps<'input'>>(
  ({ className, type, id, children, ...props }, ref) => {
    return (
      <>
        <Label
          htmlFor={id}
          className={cn(
            'cursor-pointer w-fit flex hover:border-gray-1 items-center h-9 rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
            className,
          )}
        >
          {children}
        </Label>
        <input type="file" id={id} className="hidden" ref={ref} {...props} />
      </>
    )
  },
)
FileInput.displayName = 'FileInput'

export { FileInput }
